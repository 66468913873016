import $ from "jquery";
import ScrollReveal from "scrollreveal";
import "./state-report";
$(function () {
    if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0) {
        document.getElementsByTagName("body")[0].className += " safari";
    }
    /*==================================================
	Language Switcher
=====================================================*/
    $(".language-selector > a").click(function () {
        if ($(this).parent().hasClass("language-selector")) {
            console.log($(this).parent().hasClass("language-selector"));
            $(".language-selector ul").slideToggle();
            $(this).toggleClass("active");
        }
        return false;
    });

    $("html").on("click", function (e) {
        if ($(this).hasClass("top-menu")) {
        } else {
            $(".language-selector ul").slideUp();
            $(".language-selector a").removeClass("active");
        }
    });

    /*==================================================
	Image Liquid
=====================================================*/
    if ($.fn.imgLiquid) {
        $(".banner-image,.two-column-outer,.article-image,.image-cont,.profile-outer .img-block,.news-image,.graphimage-block,.contact-bg").imgLiquid(
            { fill: true }
        );
        $(window).bind("debouncedresize", function () {
            $(
                ".banner-image,.two-column-outer,.article-image,.image-cont,.profile-outer .img-block,.news-image,.graphimage-block,.contact-bg"
            ).imgLiquid({ fill: true });
        });
    }

    /*==================================================
	Footer Menu
=====================================================*/
    $(".footer-menu h6")
        .not("h6.single")
        .click(function () {
            $(this).toggleClass("slide");
            if ($(".mb-trigger").is(":visible")) {
                $(this).parent().children(".footer-container .footer-top .footer-links .footer-single .footer-menu ul").slideToggle("slow");
            }
        });
    $(window).on("debouncedresize", function (event) {
        if ($(".mb-trigger").is(":visible")) {
            $(".footer-container .footer-top .footer-links .footer-single .footer-menu ul").hide();
        } else {
            $(".footer-container .footer-top .footer-links .footer-single .footer-menu ul").show();
        }

        /*==================================================
	Shift Content
=====================================================*/
        shiftcontent();
    });

    /*==================================================
	MatchHeight
=====================================================*/
    /* $.fn.matchHeight = matchHeight(); */
    /* $.fn.selectbox = selectbox(); */
    if ($.fn.matchHeight) {
        $(".equalheight").matchHeight({
            byRow: true,
            property: "height",
            target: null,
            remove: false,
        });
        $(".filter-single").matchHeight({
            byRow: true,
            property: "height",
            target: null,
            remove: false,
        });
        /* $(".listing-single").matchHeight({
            byRow: true,
            property: "height",
            target: null,
            remove: false,
        }); */
        $(".relatelistcont-block").matchHeight({
            byRow: true,
            property: "height",
            target: null,
            remove: false,
        });
    }

    /*===============================================
        selectbox
==================================================*/
    if ($.fn.selectbox) {
        $(".selectbox").selectbox();
    }

    /*==================================================
	Icheck Check Box
=====================================================*/
    if ($(".custom-radio").length) {
        $(".custom-radio").each(function () {
            var self = $(this),
                label = self.next(),
                label_text = label.text();

            label.remove();
            self.iCheck({
                radioClass: "icheckbox_radio",
                insert: label_text,
            });
        });
    }

    /*==================================================
	Icheck Radio Button
=====================================================*/
    if ($.fn.iCheck) {
        $(".custom-radio1").iCheck({
            radioClass: "icheckbox_radio1",
            increaseArea: "20%",
        });
    }

    /*==================================================
	Shift Content
=====================================================*/
    shiftcontent();

    /*==================================================
	Content Slider
=====================================================*/
    if ($(".content-slider").length) {
        $(".content-slider").slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: true,
        });
    }
    if ($(".listing-slider").length) {
        $(".listing-slider").slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: true,
        });
    }
});

$(window).on("load", function () {
    $(".donate form .select-amount input:radio").on("ifChecked", function (event) {
        $(".donate form input.amount-field").removeClass("checked");
        $(".donate form input.amount-field").val("");
    });
    $(".donate form input.amount-field").click(function () {
        $(".donate form .select-amount input:radio").iCheck("uncheck");
        $(".donate form input.amount-field").addClass("checked");
    });
});

$(window).on("load", function () {
    if ($(".filter-content").length) {
        $(".filter-content").isotope({
            filter: "*",
            animationOptions: {
                duration: 750,
                easing: "linear",
                queue: false,
            },
        });

        /*==================================================
		Isotope
	=====================================================*/
        if ($.fn.isotope) {
            var $container = $(".filter-content").isotope({
                itemSelector: ".filter-single",
                layoutMode: "fitRows",
                transitionDuration: ".8s",
            });
            $(".filter-header ul li a").click(function () {
                $(".filter-header ul li a.active-category").removeClass("active-category");
                $(this).addClass("active-category");
                var filterValue = $(this).attr("data-filter");
                $container.isotope({
                    filter: filterValue,
                    animationOptions: {
                        duration: 600,
                        easing: "linear",
                        queue: false,
                    },
                });
            });
        }
    }

    if ($(".filter-content").length) {
        $(".filter-content").infinitescroll(
            {
                navSelector: "#page-nav", // selector for the paged navigation
                nextSelector: "#page-nav a", // selector for the NEXT link (to page 2)
                itemSelector: ".filter-single", // selector for all items you'll retrieve
                dataType: "html",
                loading: {
                    finishedMsg: "No more pages to load.",
                    //img: '/images/loader.gif'
                },
                state: {
                    currPage: 0,
                },
                debug: false,
                pathParse: function (path, nextPage) {
                    path = ["data", ""];
                    return path;
                },
                maxPage: 5,
            },
            // call Isotope as a callback
            function (newElements, opts) {
                $("#filter-container").isotope("appended", $(newElements));
                console.log(opts.state.currPage + "=====");
            }
        );
    }
    $(window).unbind(".infscr");
    $(".btn-default.load-more").click(function () {
        $(".filter-content").infinitescroll("retrieve");
        return false;
    });
    $(document).ajaxError(function (e, xhr, opt) {
        if (xhr.status == 404) $("#page-nav a").remove();
    });

    /*==================================================
		Isotope
=====================================================*/
    if ($(".filter-content").length) {
        var $container = $(".filter-content").isotope({
            itemSelector: ".filter-single",
            layoutMode: "fitRows",
            transitionDuration: ".8s",
        });
        $(".filter-header ul li a").click(function () {
            $(".filter-header ul li a.active-category").removeClass("active-category");
            $(this).addClass("active-category");
            var filterValue = $(this).attr("data-filter");
            $container.isotope({
                filter: filterValue,
                animationOptions: {
                    duration: 600,
                    easing: "linear",
                    queue: false,
                },
            });
        });
    }
});

function shiftcontent() {
    if ($(".mb-trigger").is(":visible")) {
        $(".header-container").each(function (index) {
            $(".top-menu li").addClass("newitem");
            //$('.top-menu li').prependTo($('.header-menu ul'));
        });
    } else {
        $(".header-container").each(function (index) {
            $(".header-menu li.newitem").appendTo($(".top-menu ul"));
        });
    }
}

/* *****************************
 * TOGGLE TOP SEARCH BAR
 * *****************************
 */
var topSearchForm = $(".top-search .search");
var topSearchInput = topSearchForm.children(".search-input");
var topSearchButton = topSearchForm.children("button");

/* TOP SEARCH INPUT DYNAMIC WIDTH */
function setSearchInputWidth() {
    //debugger;
    var headerWidth = $(".header-bottom .container").outerWidth();
    var logoWidth = $(".header-logo").outerWidth();
    var topSearchWidth = $(".top-search").outerWidth();
    var navWidth = headerWidth - logoWidth - searchWidth; // Used in header full width
    console.log(headerWidth);
    if ($(window).width() <= 600) {
        var searchWidth = (headerWidth * 45) / 100;
    } else if ($(window).width() <= 1024) {
        var searchWidth = (headerWidth * 70) / 100;
    } else {
        var searchWidth = headerWidth - logoWidth - topSearchWidth;
    }
    topSearchInput.css("width", searchWidth);
}
topSearchForm.bind("close", function () {
    topSearchInput.addClass("show-hidden").css("width", "0");
    topSearchButton.removeClass("active");
    topSearchButton.children("i").removeClass("fa-times fa-paper-plane").addClass("fa-search");
});

topSearchButton
    .on("mousedown", function (e) {
        console.log("mousedown");
        var self = $(this);
        if (self.hasClass("active")) {
            self.data("buttonClosedForm", true);
        }
    })
    .on("click", function (e) {
        console.log("click");
        var self = $(this);

        if (self.data("buttonClosedForm") || self.hasClass("active")) {
            // The search form is open, active
            if (topSearchInput.val() == "") {
                e.preventDefault();
                topSearchForm.trigger("close");
            }
            console.log("closing");
            self.data("buttonClosedForm", false);
        } else {
            // The search form is closed, let's open it

            e.preventDefault();
            setSearchInputWidth();
            self.addClass("active");
            self.children("i").removeClass("fa-search fa-paper-plane").addClass("fa-times active");

            topSearchInput.val("").trigger("focus").removeClass("show-hidden");
        }
    });

topSearchInput
    .on("focusout", function (e) {
        if (!topSearchButton.data("buttonClosedForm")) {
            topSearchForm.trigger("close");
        }
    })
    .on("keyup", function (e) {
        var self = $(this);
        if (e.which == 27) {
            self.trigger("focusout");
        }
        if (!topSearchButton.hasClass("active")) {
            return;
        }
        if (self.val() != "") {
            topSearchButton.children("i").removeClass("fa-times").addClass("fa-paper-plane");
        } else {
            topSearchButton.children("i").addClass("fa-times").removeClass("fa-paper-plane");
        }
    });
/*==================================================
  Contact Labels
=====================================================*/
function renderLabelInputs() {
    var $element = $(this);
    var $label = $("label[for='" + this.id + "']");
    if ($element.val() == "") {
        $label.css("display", "");
    } else {
        $label.css("display", "none");
    }
}
$(window).on("load", function () {
    if ($(".contact-single")) {
        $(".contact-single input").each(renderLabelInputs);
        $(".contact-single input").keydown(renderLabelInputs);
        $(".contact-single input").keyup(renderLabelInputs);
    }
});

/* *****************************
 * MEAN MENU MIMIC
 * *****************************
 */
$(window).on("load", function () {
    renderMenu();
});
$(window).resize(renderMenu);
var isMobile = false;
function renderMenu() {
    var socialIcons = $(".mobile-socialIcons-content").html();
    var languagesContent = $(".mobile-languages-content").html();
    $(".responsive-menu ul li.social-icons").html('<div class="container">' + socialIcons + "</div>");
    $(".responsive-menu ul li.languages").html('<div class="container">' + languagesContent + "</div>");
    if ($(window).width() < 992) {
        if (isMobile) return;
        $(".header-container .header-menu .main-menu.responsive-menu ul li.menu-item-has-children").prepend(
            '<a href="#" class="submenu-trigger"></a>'
        );
        $(".submenu-item.menu-item-has-children").removeClass("expanded");
        $(".submenu-item.menu-item-has-children").find("> ul.sub-submenu").slideUp();
        $("ul.sub-submenu li.menu-item-has-children").removeClass("expanded");
        $("ul.sub-submenu li.menu-item-has-children").find("> ul.sub-sub-submenu").slideUp();
        isMobile = true;
        addHandlers();
    } else {
        if (!isMobile) return;
        $(".header-container .header-menu .main-menu.responsive-menu ul li.menu-item-has-children a.submenu-trigger").remove();
        $(".header-container .header-menu .main-menu.responsive-menu ul ").css("display", "");
        $(".submenu-item.menu-item-has-children").removeClass("expanded");
        $(".submenu-item.menu-item-has-children").find("> ul.sub-submenu").slideDown();
        $("ul.sub-submenu li.menu-item-has-children").removeClass("expanded");
        $("ul.sub-submenu li.menu-item-has-children").find("> ul.sub-sub-submenu").slideDown();
        isMobile = false;
    }
}
function addHandlers() {
    $(".submenu-trigger").on("click", function (e) {
        e.preventDefault();
        if ($(this).closest(".main-menu.responsive-menu ul li").hasClass("expanded")) {
            $(this).closest(".main-menu.responsive-menu ul li").removeClass("expanded");
            $(this).closest(".main-menu.responsive-menu ul li").find("> ul.submenu").slideUp();
        } else {
            $(this).closest(".main-menu.responsive-menu ul li").addClass("expanded");
            $(this).closest(".main-menu.responsive-menu ul li").find("> ul.submenu").slideDown();
        }
    });

    $(".main-menu.responsive-menu ul.submenu .menu-item-has-children .submenu-trigger").on("click", function (e) {
        e.preventDefault();
        if (!$(this).closest(".menu-item-has-children").hasClass("expanded")) {
            $(this).closest(".menu-item-has-children").removeClass("expanded");
            $(this).closest(".menu-item-has-children").find("> ul.sub-submenu").slideUp();
        } else {
            $(this).closest(".menu-item-has-children").addClass("expanded");
            $(this).closest(".menu-item-has-children").find("> ul.sub-submenu").slideDown();
        }
    });

    //$('ul.sub-submenu li > a').before('<a href="#" class="submenu-trigger"></a>');
    $(".main-menu.responsive-menu ul.sub-submenu li.menu-item-has-children  .submenu-trigger").on("click", function (e) {
        e.preventDefault();
        if (!$(this).closest(".menu-item-has-children").hasClass("expanded")) {
            $(this).closest(".menu-item-has-children").removeClass("expanded");
            $(this).closest(".menu-item-has-children").find("> ul.sub-sub-submenu").slideUp();
        } else {
            $(this).closest(".menu-item-has-children").addClass("expanded");
            $(this).closest(".menu-item-has-children").find("> ul.sub-sub-submenu").slideDown();
        }
    });
}

/* *****************************
 * ALWAYS VISIBLE HAMBURGUER MENU
 * *****************************
 */

$(window).on("load", function () {
    $(".submenu-trigger").on("click", function (e) {
        e.preventDefault();
        if ($(this).closest(".main-menu.always-hamburger ul li").hasClass("expanded")) {
            $(this).closest(".main-menu.always-hamburger ul li").removeClass("expanded");
            $(this).closest(".main-menu.always-hamburger ul li").find("> ul.submenu").slideUp();
        } else {
            $(this).closest(".main-menu.always-hamburger ul li").addClass("expanded");
            $(this).closest(".main-menu.always-hamburger ul li").find("> ul.submenu").slideDown();
        }
    });
});

/* BEGIN US Resources redesign */
$(".btn-drop-down").click(function (e) {
    let list_id = $(this).attr("list-target");

    $(".overlay-wrapper").removeClass("d-none");
    $(".section-wrapper .section").addClass("d-none");
    $(".section-wrapper ." + list_id).removeClass("d-none");
});

$(".overlay-wrapper .btn-close").click(() => {
    $(".overlay-wrapper").addClass("d-none");
    $(".section-wrapper .section").addClass("d-none");
});

$(document).keyup(function (e) {
    if (e.key === "Escape" && !$(".overlay-wrapper").hasClass("d-none")) {
        $(".overlay-wrapper").addClass("d-none");
        $(".section-wrapper .section").addClass("d-none");
    }
});

$(".overlay-wrapper").click(function (e) {
    if ($(e.target).hasClass("overlay-wrapper") || $(e.target).hasClass("btn-close")) {
        $(".overlay-wrapper").addClass("d-none");
        $(".section-wrapper .section").addClass("d-none");
    }
});

$(".nav-link").click(function () {
    /* Sidebar Engine */
    // Get personalized ID
    let content_target = $(this).attr("content-target");

    $(".navigation-tabs a").removeClass("active");
    $(this).addClass("active");

    /* Content Engine */
    // Hide all content containers
    $(".issues-topics .content ").addClass("d-none");
    //Show Specific content
    $(".issues-topics .content." + content_target).removeClass("d-none");
});

function callback(mutationList, observer) {
    mutationList.forEach(function (mutation) {
        if (mutation.type === "attributes" && mutation.attributeName === "class") {
            $(".at-row.EmailAddress .MobilePhone").appendTo(".at-row.PostalCode");
            $(".at-row.PostalCode").addClass("MobilePhone");
            $(".at-row.EmailAddress .MobilePhone").remove();
            $(".at-row.PostalCode.MobilePhone").removeClass("at-row-solo");
            $(".at-row.EmailAddress").removeClass("MobilePhone");
            $(".at-row.EmailAddress").addClass("at-row-solo");
            $(".at-row.SmsLegalDisclaimer").hide();

            $(".at-row.MobilePhone .MobilePhone").keyup(function () {
                $(".at-row.SmsLegalDisclaimer").show();
            });
        }
    });
}

const observer = new MutationObserver(function (mutationList) {
    mutationList.forEach(function (mutation) {
        if (mutation.type === "attributes" && mutation.attributeName === "class") {

            const translations = {
                fr: {
                    "First Name": "Prénom",
                    "Last Name": "Nom",
                    Email: "Adresse e-mail",
                    Submit: "Envoyer",
                },
                es: {
                    "First Name": "Nombre",
                    "Last Name": "Apellido",
                    Email: "Correo electrónico",
                    Submit: "Enviar",
                },
            };

            for (const lang in translations) {
                if (Object.hasOwnProperty.call(translations, lang)) {
                    const translation = translations[lang];
                    $(`.${lang} .ngp-form`).find(`[placeholder='First Name\*']`).attr("placeholder", translation["First Name"]);
                    $(`.${lang} .ngp-form`).find(`[placeholder='Last Name\*']`).attr("placeholder", translation["Last Name"]);
                    $(`.${lang} .ngp-form`).find(`[placeholder='Email\*']`).attr("placeholder", translation["Email"]);
                    $(`.${lang} .ngp-form`).find(`[value='Submit']`).attr("value", translation["Submit"]);
                }
            }
        }
    });
});

if ($(".ngp-form").length) {
    const form = document.querySelector(".ngp-form");
    const options = {
        attributes: true,
    };

    observer.observe(form, options);
}

$(".row-title").on("click", function (e) {
    let id = $(e.currentTarget).data("row-content");

    $(".row-body")
        .not("#" + id)
        .addClass("d-none");
    $("#" + id).toggleClass("d-none");

    if (!$("#" + id).hasClass("d-none")) {
        $(e.currentTarget).find("i.fa-angle-up").removeClass("d-none");
        $(e.currentTarget).find("i.fa-angle-down").addClass("d-none");
    } else {
        $(e.currentTarget).find("i.fa-angle-down").removeClass("d-none");
        $(e.currentTarget).find("i.fa-angle-up").addClass("d-none");
    }
});

ScrollReveal().reveal(".sr-slide-up", {
    distance: "60px",
    origin: "bottom",
    opacity: 0,
    duration: 600,

    easing: "cubic-bezier(.39,.01,.59,1)",
    reset: false,
    viewOffset: {
        top: 100,
        bottom: 100,
    },
});
ScrollReveal().reveal(".sr-slide-right", {
    origin: "left",
    opacity: 1,
    duration: 0,
    reset: false,
    afterReveal: (el) => {
        $(el).delay(500).animate({ width: "120px" });
    },
});

$(".emerging-products-header .btn-close-menu, .emerging-products-header .btn-open-menu").on("click", function () {
    $(".emerging-products-header .header-wrapper").toggleClass("active");
});



$(".open-products-nav button").on("click", function () {
    $(".buttons-wrapper").toggleClass("open");
    $(".open-products-nav button .fas").toggleClass("fa-angle-up");
});

var postToShow = 10;
var hasFilters = false;

function filterEmergingCards(className) {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("type")) {
        let producType = urlParams.get("type");
        hasFilters = true;
        $(className).each(function (index, el) {
            if ($(el).find("li[data-type=" + producType + "]").length == 0) {
                $(el).addClass("d-none");
            } else {
                $(el).removeClass("d-none");
            }
        });
    }
}

function sliceEmergingCards() {
    let border = "border-bottom border-0 border-dark-subtle";

    // Show more news
    $(".row-news .new-article").each(function (index, element) {
        if (index > postToShow - 1) {
            $(element).addClass("d-none");
            $(element).removeClass(border);
        } else {
            $(element).removeClass("d-none");
            $(element).addClass(border);
        }
    });

    // Remove last child border
    $(".row-news .new-article:visible").last().removeClass(border);

    // Call filters
    filterEmergingCards(".row-news .new-article:visible");

    // Show/hide show more button
    if ($(".row-news .new-article.d-none").length == 0) {
        $("#view-more-emerging-products-news").addClass("d-none");
    } else {
        $("#view-more-emerging-products-news").removeClass("d-none");
    }
}

$(document).ready(function () {
    let resourcesPaths = ["/emerging-products/resources", "/es/productos-emergentes/recursos", "/fr/produits-emergents/ressources"];
    let newsPaths = ["/emerging-products/news", "/es/productos-emergentes/noticias", "/fr/produits-emergents/nouvelles"];

    if (resourcesPaths.indexOf(window.location.pathname) > -1) {
        filterEmergingCards(".emerging-products-resources-cards-row .col-md-4");
    } else if (newsPaths.indexOf(window.location.pathname) > -1) {
        sliceEmergingCards();
    }
});
$("#view-more-emerging-products-news").on("click", function () {
    postToShow += postToShow;
    console.log(postToShow);
    sliceEmergingCards();
});

$(".navbar-toggle").on("click", (e) => {
    $(e.currentTarget).toggleClass("is-open");
});

const myOffcanvas = document.getElementById("js-bootstrap-offcanvas");
if (myOffcanvas) {
    myOffcanvas.addEventListener("hidden.bs.offcanvas", (event) => {
        $(".navbar-toggle").removeClass("is-open");
    });
}

$("#selectDate").on("change", function (e) {
    $("#form-selectDate").submit();
});

// IMG Liquid
$(document).ready(function () {
    let images = $(".featured-single .featured-image img");

    images.each(function (index, element) {
        let url = $(element).attr("src");

        $(element)
            .parent()
            .css({
                "background-image": `url("${url}")`,
            });
        $(element).hide();
    });
});
